import { createAsyncThunk } from "@reduxjs/toolkit";
import * as URL from "../../../../helper/url_helper";
import { post, updateToken, get, put } from "../../../../helper/api_helper";

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

// export const getChatList = createAsyncThunk(
//   "getChatList",
//   async ({ data, callBack }, Thunk) => {
//     try {
//       const response = await post(URL.GET_CHAT_LIST, data);
//       callBack && callBack(null, response);
//       return response.data;
//     } catch (error) {
//       console.log(error, "<===error");
//       callBack && callBack(error);
//       return Thunk.rejectWithValue(error);
//     }
//   }
// );

export const getChatDetails = createAsyncThunk(
  "getChatDetails",
  async ({ data, callBack }, Thunk) => {
    try {
      console.log(data, "dataREacher");
      const response = await post(`${URL.GET_CHAT_DETAILS}/${data.id}`, data);
      callBack && callBack(null, response);
      return response.data;
    } catch (error) {
      console.log(error, "<===error");
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const chatWithAI = createAsyncThunk(
  "chatWithAI",
  async ({ data, callBack }, Thunk) => {
    try {
      // console.log(data, "asdfaRead");
      const response = await post(URL.CREATE_CHAT, data);
      console.log(response, "response=======>");

      callBack && callBack(null, response);
      return response.data;
    } catch (error) {
      console.log(error, "<===error");
      callBack && callBack(error);
      return Thunk.rejectWithValue(error);
    }
  }
);
