import { chatWithAI, getChatList } from "./actions";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  chat: [],
};

export const ChatSlice = createSlice({
  name: "Chat",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    // builder
    //   .addCase(getChatList.pending, (state, action) => {
    //     state.listLoading = true;
    //   })
    //   .addCase(getChatList.fulfilled, (state, action) => {
    //     state.ChatsList = action.payload;
    //     state.listLoading = false;
    //   })
    //   .addCase(getChatList.rejected, (state, action) => {
    //     state.ChatsList = initialState.characters;
    //     state.listLoading = false;
    //   });

    builder
      .addCase(chatWithAI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(chatWithAI.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(chatWithAI.rejected, (state, action) => {
        state.loading = false;
      });
  },
});
