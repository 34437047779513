import React, { useEffect, useRef, useState } from "react";

const LocationSearch = ({ address, setAddress }) => {
  const inputRef = useRef(null);

  console.log(address, "asdfasdfAddress");

  useEffect(() => {
    // Load the Google Places API script
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBaprSHLBmUMmXIb2gU1ULpyYgJJdg5D7c&libraries=places`;
    script.async = true;
    script.onload = () => {
      // Initialize the Autocomplete feature once the script is loaded
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current
      );
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        setAddress(place.formatted_address || ""); // You can get more details as needed
      });
    };
    document.head.appendChild(script);
    return () => {
      // Cleanup the script when the component is unmounted
      if (script) {
        script.remove();
      }
    };
  }, []);

  return (
    <div>
      <input
        ref={inputRef}
        type="text"
        className="form-control"
        placeholder="Search for a location"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
      />
      {/* {address && <div>Selected Location: {address}</div>} */}
    </div>
  );
};

export default LocationSearch;
