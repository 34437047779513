"use client";
/* Core */
import { createSlice } from "@reduxjs/toolkit";

/* Instruments */
import {
  editProfile,
  forgotPasswordEmailVerifyOTP,
  login,
  registerEmailAndOtpSend,
  registerUser,
  resetForgotPassword,
  socialLogin,
  forgotPassword,
  verifyForgotEmail,
  logout,
  delProfile,
  profileDetails,
} from "./actions";
let token = null;
let userData = null;
if (typeof window !== "undefined") {
  token = localStorage.getItem("authToken");
  userData = JSON.parse(localStorage.getItem("authUser"));
}
const initialState = {
  user: userData,
  authToken: token || null,
  signupuser: null,
  forgetpass: null,
  isAuthenticated: token ? true : false,
  routeType: null,
  loading: false,
};
export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload;
        state.authToken = action.payload.token;
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.user = initialState.user;
        state.loading = false;
      });
    builder
      .addCase(registerEmailAndOtpSend.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(registerEmailAndOtpSend.fulfilled, (state, action) => {
        state.user = action.payload;
        state.authToken = action.payload.token;
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(registerEmailAndOtpSend.rejected, (state, action) => {
        state.user = initialState.user;
        state.loading = false;
      });
    builder
      .addCase(socialLogin.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(socialLogin.fulfilled, (state, action) => {
        state.user = action.payload;
        state.authToken = action.payload.token;
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(socialLogin.rejected, (state, action) => {
        state.user = initialState.user;
        state.loading = false;
      });
    builder
      .addCase(forgotPassword.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.user = initialState.user;
        state.loading = false;
      });
    builder
      .addCase(registerUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.authToken = action.payload.token;
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.user = initialState.user;
        state.loading = false;
      });
    builder
      .addCase(forgotPasswordEmailVerifyOTP.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(forgotPasswordEmailVerifyOTP.fulfilled, (state, action) => {
        state.user = action.payload;
        state.authToken = action.payload.token;
        // state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(forgotPasswordEmailVerifyOTP.rejected, (state, action) => {
        state.user = initialState.user;
        state.loading = false;
      });
    builder
      .addCase(verifyForgotEmail.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(verifyForgotEmail.fulfilled, (state, action) => {
        state.user = action.payload;
        state.authToken = action.payload.token;
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(verifyForgotEmail.rejected, (state, action) => {
        state.user = initialState.user;
        state.loading = false;
      });
    builder
      .addCase(resetForgotPassword.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(resetForgotPassword.fulfilled, (state, action) => {
        state.user = action.payload;
        state.authToken = action.payload.token;
        // state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(resetForgotPassword.rejected, (state, action) => {
        state.user = initialState.user;
        state.loading = false;
      });
    builder
      .addCase(editProfile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(editProfile.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(editProfile.rejected, (state, action) => {
        state.user = initialState.user;
        state.loading = false;
      });
    builder
      .addCase(profileDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(profileDetails.fulfilled, (state, action) => {
        state.user = action.payload;
        state.authToken = action.payload.token;
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(profileDetails.rejected, (state, action) => {
        state.user = initialState.user;
        state.loading = false;
      });
    builder
      .addCase(logout.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state = initialState;
      })
      .addCase(logout.rejected, (state, action) => {
        state = initialState;
        state.loading = false;
      });
    builder
      .addCase(delProfile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(delProfile.fulfilled, (state, action) => {
        state = initialState;
      })
      .addCase(delProfile.rejected, (state, action) => {
        state = initialState;
        state.loading = false;
      });
  },
});
